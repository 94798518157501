import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import Seo from '../components/Seo'
import Img from 'gatsby-image'
import "./about.scss";
import {
  FacebookIcon
} from 'react-share'
// eslint-disable-next-line
import FederImg, {
  ReactComponent as FederLogo,
} from '../images/feder.svg'
// eslint-disable-next-line
import '../images/forderprog.png'
// eslint-disable-next-line
import IbbImg, {
  ReactComponent as IbbLogo,
} from '../images/ibb.svg'

const aboutTemplate = props => {
  let { about, seo, logo } = props.data
  let slug = props.location.pathname
  return <Layout currentSlug={slug}>
      <Seo {...seo} />
      <div className="b-about">
        <div className="l-container l-container--card-width">
          <div className="b-about__text"> </div>
          <div className="b-about__wrapper" dangerouslySetInnerHTML={{ __html: about.info.childMarkdownRemark.html }}/>
          
        </div>
        <div className="b-about-copyright">
           <ul className="b-about-logos">
            <li className="b-about-logos__item">
             <IbbLogo />
            </li>
            <li className="b-about-logos__item">
             <Img fixed={logo.childImageSharp.fixed} />
            </li>
            <li className="b-about-logos__item">
             <FederLogo />
            </li>
           </ul>
          2019 г. IBB Minsk: {' '}
          <a href="https://www.facebook.com/ibbminsk/" target="_blank" rel="noopener noreferrer" title="Ibb Minsk" className="b-about-copyright-link b-about-copyright-link--wa">
           <FacebookIcon size={22} className="h-inline" /></a><br/>
           Разработка сайта <a href="https://www.linkedin.com/in/vally-pepyako/" target="_blank" title="Vally Pepyako" className="b-about-copyright-link"> Vally Pepyako</a>
        </div>
      </div>
    </Layout>
}
export default aboutTemplate

export const aboutQuery = graphql`
  query aboutQuery {
    logo: file(relativePath: { eq: "forderprog.png" }) {
      childImageSharp {
        fixed(width: 259, height: 100){
          ...GatsbyImageSharpFixed
        }
      }
    }
    about: contentfulAbout {
      slug
      title
      info {
        childMarkdownRemark {
          html
        }
      }
    }
    allImages: contentfulMainPage {
      images {
        title
        lessonLink {
          slug
          title
        }
        image {
          id
          file {
            url
            fileName
            contentType
          }
          fluid(maxWidth: 641) {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
    seo: contentfulAbout {
      title
      description
      image {
        file {
          url
        }
      }
    }
  }
`
